import { forwardRef, useEffect, useState, useCallback, useRef } from "react";
import { useUnityContext } from "react-unity-webgl";
import dynamic from 'next/dynamic';
import Head from 'next/head';

import config from 'global/config';
import 'common/unityInterop.js';

import SuppressConsoleErrors from '../components/SuppressConsoleErrors';

const Unity = forwardRef((props, ref) => (<DynamicUnity unityRef={ref} {...props} />));
let DynamicUnity = dynamic(import('components/DynamicUnity'), {
  ssr: false,
});

function Home() {
  const [element, setElement] = useState(null);
  const parentRef = useRef(null); // Reference to the parent container

  const handleRef = useCallback(element => {
    setElement(element);
  }, []);

  const { UNITY_BUILD_URL, UNITY_BUILD_SUFFIX, UNITY_BUILD_TARGET, UNITY_BUILD_NUMBER } = config;

  var BASE_URL = UNITY_BUILD_NUMBER
    ? `${UNITY_BUILD_URL}/Builds/${UNITY_BUILD_NUMBER}`
    : `${UNITY_BUILD_URL}`;
  if (process.env.CHAIN_ENV !== 'emulator') {
    BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
  }

  const { unityProvider, isLoaded, UNSAFE__detachAndUnloadImmediate } = useUnityContext({
    loaderUrl: `${BASE_URL}/Build/${UNITY_BUILD_TARGET}.loader.js`,
    dataUrl: `${BASE_URL}/Build/${UNITY_BUILD_TARGET}.data${UNITY_BUILD_SUFFIX}`,
    frameworkUrl: `${BASE_URL}/Build/${UNITY_BUILD_TARGET}.framework.js${UNITY_BUILD_SUFFIX}`,
    codeUrl: `${BASE_URL}/Build/${UNITY_BUILD_TARGET}.wasm${UNITY_BUILD_SUFFIX}`,
    streamingAssetsUrl: `${BASE_URL}/StreamingAssets`,
    matchWebGLToCanvasSize: true,ebGLContextAttributes: {
      alpha: true,  // Enable alpha channel for transparency
    }
  });

  // Updated resize function to use parent container width
  const resize = useCallback(() => {
    if (!element || !parentRef.current) return;

    // Get the width of the parent container
    const parentWidth = parentRef.current.offsetWidth;
    // Calculate height based on the parent width with a fixed aspect ratio (16:9)
    const height = Math.floor(parentWidth * (9 / 16));

    // Apply width and height to the player element
    element.style.width = `${parentWidth}px`;
    element.style.height = `${height}px`;
  }, [element]);

  useEffect(() => {
    // Run resize on initial load
    resize();

    // Add resize event listener
    window.addEventListener('resize', resize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useEffect(() => {
    if (isLoaded) {
      element.style.cursor = 'pointer';
      element.addEventListener('touchstart', () => { /* Handle touch start */ });
      element.addEventListener('touchmove', (e) => { e.stopPropagation(); });
      element.addEventListener('touchend', () => { /* Handle touch end */ });
      return () => UNSAFE__detachAndUnloadImmediate();
    }
  }, [isLoaded, UNSAFE__detachAndUnloadImmediate, element]);

  return (
    <div className="w-full h-screen overflow-hidden relative">
      <Head>
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Head>
      <div className="w-full h-[90%] flex justify-center items-center" ref={parentRef}>
        <div className="m-auto w-full">
          <SuppressConsoleErrors />
          <Unity
            ref={handleRef}
            className="m-auto w-full h-full"
            unityProvider={unityProvider}
          />
        </div>
      </div>
      <div className="absolute bottom-0 w-full h-[12.5%] text-center text-white p-4 flex justify-center space-x-4">
      <a
        href={`${BASE_URL}/Static/PrivacyPolicy.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
      >
        Privacy Policy
      </a>
      <a
        href={`${BASE_URL}/Static/ToS.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
      >
        Terms of Service
      </a>
    </div>
    </div>
  );
}

const NoSsr = props => (
  <Home>{props.children}</Home>
);

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
});
