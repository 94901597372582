import React, { useEffect } from 'react';

const SuppressConsoleErrors = () => {
  useEffect(() => {
    // Backup the original console functions
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;

    // Override console.error
    console.error = function(message) {
      // Add your filtering logic here if needed
      // For now, this suppresses all errors.
    };

    // Optionally, override console.warn as well
    console.warn = function(message) {
      // Add your filtering logic here if needed
      // For now, this suppresses all warnings.
    };

    // Cleanup function to revert overrides
    return () => {
      console.error = originalConsoleError;
      console.warn = originalConsoleWarn;
    };
  }, []);

  return null; // This component doesn't render anything
};

export default SuppressConsoleErrors;
