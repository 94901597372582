const CHAIN_ENV = process.env.NEXT_PUBLIC_CHAIN_ENV

const ACCESS_API = process.env.NEXT_PUBLIC_ACCESS_API
const WALLET_DISCOVERY = process.env.NEXT_PUBLIC_WALLET_DISCOVERY
const WALLET_DISCOVERY_METHOD = process.env.NEXT_PUBLIC_WALLET_DISCOVERY_METHOD

const APP_DETAIL_TITLE = process.env.NEXT_PUBLIC_APP_DETAIL_TITLE
const APP_DETAIL_ICON = process.env.NEXT_PUBLIC_APP_DETAIL_ICON

const ADMIN_ADDRESS = process.env.NEXT_PUBLIC_ADMIN_ADDRESS
const ARENABOYZ_ADDRESS = process.env.NEXT_PUBLIC_ARENABOYZ_ADDRESS
const ARENABOYZGLOBALS_ADDRESS = process.env.NEXT_PUBLIC_ARENABOYZGLOBALS_ADDRESS
const ARENABOYZHISTORY_ADDRESS = process.env.NEXT_PUBLIC_ARENABOYZHISTORY_ADDRESS
const ABROYALEGAME_ADDRESS = process.env.NEXT_PUBLIC_ABROYALEGAME_ADDRESS
const FUNGIBLETOKEN_ADDRESS = process.env.NEXT_PUBLIC_FUNGIBLETOKEN_ADDRESS
const FLOWTOKEN_ADDRESS = process.env.NEXT_PUBLIC_FLOWTOKEN_ADDRESS
const NONFUNGIBLETOKEN_ADDRESS = process.env.NEXT_PUBLIC_NONFUNGIBLETOKEN_ADDRESS
const METADATAVIEWS_ADDRESS = process.env.NEXT_PUBLIC_METADATAVIEWS_ADDRESS

const UNITY_BUILD_URL = process.env.NEXT_PUBLIC_UNITY_BUILD_URL
const UNITY_EXPERIMENTAL_URL = process.env.NEXT_PUBLIC_UNITY_EXPERIMENTAL_URL
const UNITY_BUILD_SUFFIX = process.env.NEXT_PUBLIC_UNITY_BUILD_SUFFIX
const UNITY_BUILD_TARGET = process.env.NEXT_PUBLIC_UNITY_BUILD_TARGET
const UNITY_BUILD_NUMBER = process.env.NEXT_PUBLIC_UNITY_BUILD_NUMBER

const METADATA_URL = process.env.NEXT_PUBLIC_METADATA_URL
const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL
const WEBCDN_URL = process.env.NEXT_PUBLIC_WEBCDN_URL

const config = {
  CHAIN_ENV,

  ACCESS_API,
  WALLET_DISCOVERY,
  WALLET_DISCOVERY_METHOD,

  APP_DETAIL_TITLE,
  APP_DETAIL_ICON,

  ADMIN_ADDRESS,
  ARENABOYZ_ADDRESS,
  ABROYALEGAME_ADDRESS,
  ARENABOYZGLOBALS_ADDRESS,
  ARENABOYZHISTORY_ADDRESS,
  FUNGIBLETOKEN_ADDRESS,
  FLOWTOKEN_ADDRESS,
  NONFUNGIBLETOKEN_ADDRESS,
  METADATAVIEWS_ADDRESS,

  UNITY_BUILD_URL,
  UNITY_EXPERIMENTAL_URL,
  UNITY_BUILD_SUFFIX,
  UNITY_BUILD_TARGET,
  UNITY_BUILD_NUMBER,

  METADATA_URL,
  CDN_URL,
  WEBCDN_URL,
}

export default config
