import {get} from 'lodash-es'
import * as fcl from '@onflow/fcl'

import {
  arenaboyz,
  abgame,
  init,
  isLoggedIn,
} from '@crypthulhu/ab-contracts'

// This resolves to an empty array so it can be used with AsyncJsCall
const sleep = ms => new Promise(resolve => setTimeout(() => resolve([]), ms))

// Put some stuff in global object so we can reach it easily from unity's jslib
// modules
globalThis.crypthulhu = {
  fcl,
  init,
  isLoggedIn,
  waitForTx,
  waitForMintTx,
  arenaboyz,
  abgame,

  // Helpers used in AsyncJsCall implementation
  get,
  strToPtr,
  toJsonPtr,
  openPopup,
  sleep,
}

async function waitForTx(txId) {
  return await fcl.tx(txId).onceSealed()
}

async function waitForMintTx(txId) {
  return await fcl.tx(txId).onceSealed()
}

function strToPtr(str) {
  // Notice Module is a global object defined by emscripten's runtime.
  // Unfortunately Unity doesn't export all the functions so add some of them
  // manually in AsyncJsCall.Init when starting the game
  // NOTE: this is where you will get a crash if any of the unity modules havent loaded properly-
  // check hosting settings, make sure that your compression matches.
  if (str === undefined || str === null) return 0
  const strLen = Module.lengthBytesUTF8(str) + 1
  var strPtr = Module._malloc(strLen);
  Module.stringToUTF8(str, strPtr, strLen);
  return strPtr 
}

function toJsonPtr(obj) {
  return strToPtr(JSON.stringify(obj))
}

async function openPopup(url, target, width, height) {
  console.log('openPopup', url, target, width, height)
  const win = globalThis
  const w = width ?? 640
  const h = width ?? 770
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2 
  win.open(
    url,
    target, 
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
  return []
}